import { regexConstants } from "../../../../constants/regEx"
export const formValues = {
    removal_date: null,
    removal_reason: null,
    tsn_at_removal: null,
    csn_at_removal: null,
    sv_completion_date: null,
    sv_location_mro: '',
    sv_workscope: '',
    sv_type: '',
    remarks: "",
    attachments: [],
    checklist: [],
    folder_uuid: '',
    sv_reset_tslsv_cslsv:false,   
}

export const taskErrorCode = {
    removal_date: {
        0: "",
        1: "Required",
        5: "Please enter valid date",
    },
    removal_date_obj: {
        required: true,
        datePattern: true,
    },
    removal_reason: {
        0: "",
        1: "Required",
    },
    removal_reason_obj: {
        required: true,
    },
    tsn_at_removal: {
        0: "",
        1: "Required",
    },
    tsn_at_removal_obj: {
        required: true,
    },
    csn_at_removal: {
        0: "",
        1: "Required",
    },
    csn_at_removal_obj: {
        required: true,
    },
    sv_completion_date: {
        0: "",
        1: "Required",
        5: "Please enter valid date",
    },
    sv_completion_date_obj: {
        required: true,
        datePattern: true,
    },
    sv_type: {
        0: "",
        1: "Required",
    },
    sv_type_obj: {
        required: true,
    },
}
