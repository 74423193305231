export const adSmbcFilterOptions = {
    'applicability': {
        'inputType': 'dropdown',
        'title': 'Applicability',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'id',
        'multiple': true
    },
    'ad_compliance_status': {
        'inputType': 'dropdown',
        'title': 'AD Ccompliance Status',
        'options': [{ label: 'OPEN', value: '0' }, { label: 'COMPLIED', value: '1' }, { label: 'PARTIALLY COMPLIED', value: '2' }, { label: 'NOT APPLICABLE', value: '3' }],
        'labelKey': 'label',
        'valueKey': 'label',
    },
    'faa_ad': {
        'inputType': 'text',
        'title': 'FAA AD',
    },
    'faa_superseded': {
        'inputType': 'text',
        'title': 'FAA Superseded',
    },
    'easa_ad': {
        'inputType': 'text',
        'title': 'EASA AD',
    },
    'easa_superseded': {
        'inputType': 'text',
        'title': ' EASA Superseded',
    },
}

export const adFilterOptions = {
    'ata': {
        'inputType': 'text',
        'title': 'ATA Chapter',
    },
    'ad_no': {
        'inputType': 'text',
        'title': 'Reference Number',
    },
    'issuing_authority': {
        'inputType': 'text',
        'title': 'Issuing Authority',
    },
    'applicability': {
        'inputType': 'dropdown',
        'title': 'Applicability',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'id',
        'multiple': true
    },
    'ad_compliance_status': {
        'inputType': 'dropdown',
        'title': 'AD Ccompliance Status',
        'options': [{ label: 'OPEN', value: '0' }, { label: 'COMPLIED', value: '1' }, { label: 'PARTIALLY COMPLIED', value: '2' }, { label: 'NOT APPLICABLE', value: '3' }],
        'labelKey': 'label',
        'valueKey': 'label',
    },
}

export const sbFilterOptions = {
    'ata_chapter': {
        'inputType': 'text',
        'title': 'ATA Chapter',
    },
    'type': {
        'inputType': 'dropdown',
        'title': 'AD Ccompliance Status',
        'options': [{ label: 'Alert', value: '0' }, { label: 'Standard', value: '1' }, { label: 'Optional', value: '2' }],
        'labelKey': 'label',
        'valueKey': 'label',
    },
    'sb_applicability': {
        'inputType': 'dropdown',
        'title': 'Applicability',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'id',
        'multiple': true
    },
    'sb_compliance_status': {
        'inputType': 'dropdown',
        'title': 'SB Ccompliance Status',
        'options': [{ label: 'OPEN', value: '0' }, { label: 'COMPLIED', value: '1' }, { label: 'PARTIALLY COMPLIED', value: '2' }, { label: 'NOT APPLICABLE', value: '3' }],
        'labelKey': 'label',
        'valueKey': 'label',
    },
    'sb_ref_number': {
        'inputType': 'text',
        'title': 'SB Ref Number',
    },
}
export const sbSmbcFilterOptions = {
    'sb_applicability': {
        'inputType': 'dropdown',
        'title': 'Applicability',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'id',
        'multiple': true
    },
    'sb_compliance_status': {
        'inputType': 'dropdown',
        'title': 'SB Ccompliance Status',
        'options': [{ label: 'OPEN', value: '0' }, { label: 'COMPLIED', value: '1' }, { label: 'PARTIALLY COMPLIED', value: '2' }, { label: 'NOT APPLICABLE', value: '3' }],
        'labelKey': 'label',
        'valueKey': 'label',
    },
    'sb_category': {
        'inputType': 'text',
        'title': 'SB category',
    },
    'sb_ref_number': {
        'inputType': 'text',
        'title': 'SB Ref Number',
    },
}

export const psFilterOptions = {
    'esn': {
        'inputType': 'text',
        'title': 'Serial Number',
    },
    'engine_model': {
        'inputType': 'dropdown',
        'title': 'Engine Model',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'id',
    },
    'engine_type': {
        'inputType': 'dropdown',
        'title': 'Engine Type',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'id',
    },
    'location': {
        'inputType': 'text',
        'title': 'Location',
    },
    'status': {
        'inputType': 'dropdown',
        'title': 'Status',
        'options': [{ label: 'Archive', value: '5' }, { label: 'On Lease', value: '1' }, { label: 'Off Lease', value: '2' }, { label: 'On Ground', value: '3' }, { label: 'Written Off/ Sold / Part out', value: '4' },],
        'labelKey': 'label',
        'valueKey': 'value',
    },
}

export const svOverviewFilterOptions = {
    'esn': {
        'inputType': 'text',
        'title': 'Serial Number',
    },
    'engine_model': {
        'inputType': 'dropdown',
        'title': 'Engine Model',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'id',
    },
    'engine_type': {
        'inputType': 'dropdown',
        'title': 'Engine Type',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'id',
    },
    'current_sv_location': {
        'inputType': 'text',
        'title': 'Location',
    },
    'status': {
        'inputType': 'dropdown',
        'title': 'Status',
        'options': [{ label: 'Archive', value: '5' }, { label: 'On Lease', value: '1' }, { label: 'Off Lease', value: '2' }, { label: 'On Ground', value: '3' }, { label: 'Written Off/ Sold / Part out', value: '4' },],
        'labelKey': 'label',
        'valueKey': 'value',
    },
}
export const inventoryObj = {
    description:'',
    serial_number:'',
    part_number:'',
    owner:'',
    location:'',
    linked_asset:'',
    status:'',
    files:[]
}
export const inventoriesFilters = {
    'description':{
        'inputType': 'text',
        'title': 'Description',
    },
    'serial_number':{
        'inputType': 'text',
        'title': 'Serial Number',
    },
    'part_number':{
        'inputType': 'text',
        'title': 'Part Number',
    },
    'owner':{
        'inputType': 'text',
        'title': 'Owner',
    },
    'location':{
        'inputType': 'text',
        'title': 'Location',
    },
    'linked_asset':{
        'inputType': 'text',
        'title': 'Linked Asset',
    },
    'status':{
        'inputType': 'text',
        'title': 'Status',
    }
}
