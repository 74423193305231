import React, { useState } from "react";
import { TableCell, TableRow } from "@material-ui/core"
import { withRouter } from "react-router-dom";

const PreservationListing = ({item}) => {
    return (
        <>
            <TableRow >
                <TableCell>{item.sael_portfolio ? item.sael_portfolio : "--"}</TableCell>
                <TableCell>{item.engine_model ? item.engine_model : "--"}</TableCell>
                <TableCell>{item.country ? item.country : "--"}</TableCell>
                <TableCell>{item.status ? item.status?.label : "--"}</TableCell>
                <TableCell>{item.tail_number_this_month ? item.tail_number_this_month : "--"}</TableCell>
                <TableCell>{item.preservation_date ? item.preservation_date : "--"}</TableCell>
                <TableCell>{item.preservation_expiry_date ? item.preservation_expiry_date : "--"}</TableCell>
                <TableCell>{item.days_to_preservation_expiry ? item.days_to_preservation_expiry : "--"}</TableCell>
            </TableRow>
        </>
    )
}

export default withRouter(PreservationListing)
