import FleetAdListing from "./FleetAdListing";
import FleetAdTableHeader from "./FleetAdTableHeader";
import AddEditFleetAD from "./AddEditFleetAD";
import FleetSbListing from "./FleetSbListing";
import FleetSbTableHeader from "./FleetSbTableHeader";
import AddEditFleetSB from "./AddEditFleetSB";
import PreservationListing from "./PreservationListing";
import PresevationHeaders from "./PresevationHeaders";
import SvOverviewHeaders from "./SvOverviewHeaders";
import SvOverviewListing from "./SvOverviewListing";
import AddEditInventory from "./AddEditInventory";
import InventoriesListing from "./InventoriesListing";
import InventoriesHeader from "./InventoriesHeader";
import InventoryAttachments from "./InventoryAttachments";
import ImportInventory from "./ImportInventory";
export{
    FleetAdListing,
    FleetAdTableHeader,
    AddEditFleetAD,
    FleetSbListing,
    FleetSbTableHeader,
    AddEditFleetSB,
    PreservationListing,
    PresevationHeaders,
    SvOverviewHeaders,
    SvOverviewListing,
    AddEditInventory,
    InventoriesListing,
    InventoriesHeader,
    InventoryAttachments,
    ImportInventory
}

export const fleetSbData={
    asset_type_id:null,
    ata_chapter:null,
    engine_family:{},
    certificate_of_conformity:null,
    description:null,
    effectivity:null,
    effectivity_date:null,
    release_certificate:null,
    remark:null,
    revision:null,
    revision_date:null,
    sb_applicability:null,
    sb_category:null,
    sb_compliance_csn:null,
    sb_compliance_date:null,
    sb_compliance_dfp:null,
    sb_compliance_status:null,
    sb_compliance_tsn:null,
    sb_issued_by:null,
    sb_ref_number:null,
    subject:null,
    type:null
}