import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, CircularProgress } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
const DeletePopUp = ({ modal, title, content, toggleModalFn, deleteRecordFn, confirmText, cancelText, cancelColor, confirmColor }) => {
	return (
		<Dialog
			open={modal}
			onClose={toggleModalFn}
			aria-labelledby="scroll-dialog-title"
			className='delete-dialogue-style'
		>
			<DialogTitle id="scroll-dialog-title">
				{title ? title : 'Delete'}
			</DialogTitle>
			<DialogContent dividers={true}>
				<div className="delete-record-modal" style={isMobile ? { width: 'auto' } : { }}>
					<div className="">
						{content}
					</div>
					<div className="actions-cta">
						<Button variant="outlined" onClick={toggleModalFn} color={cancelColor ? cancelColor : 'primary'}>{cancelText ? cancelText : 'Cancel'}</Button>
						<Button variant="contained" onClick={deleteRecordFn} color={confirmColor ? confirmColor : "secondary"}>{confirmText ? confirmText : 'Delete'}</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	)
}
export default DeletePopUp;
